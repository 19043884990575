import react from 'react'
import {useEffect, useState} from 'react'

// import {RichTextEditor, EditorValue} from './textEditor'

//import {TopBar} from "./topbar.js"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';



// var editorValue = new EditorValue();
// console.log("editorValue", editorValue.isState())


export const BookPage = (props) => {



    return (
        <Box sx={{borderWidth:5, textAlign:"center", width:"100%", align:"center"}}>
          <br/><br/>
          <Typography variant="h4"> 
              Welcome to Orchestrate Books.
          </Typography> 
          <br/>
          <Typography variant="h6">
          Reinvigorating classic literature using AI.
          </Typography>
          <br/><br/><br/>
          <Typography variant="body1"> 
          <a href="https://www.amazon.com/Bridge-San-Luis-Rey-Illustrated/dp/B0C9SDHKXH/ref=sr_1_1?crid=22CLA92C5TUQZ&keywords=Bridge+of+San+Luis+Rey+abridged&qid=1689958320&sprefix=bridge+of+san+luis+rey+abridge%2Caps%2C199&sr=8-1">
            <img src="https://m.media-amazon.com/images/I/41CVc6+DsHL._SY498_BO1,204,203,200_.jpg"/>
          </a>
          <br/>
          <br/>  Thornton Wilder won the Pulitzer Prize in 1926 for The Bridge of San Luis Rey. 
          <br/>  Here Orchestrate Books pays homage to his work with an abridged and illustrated version. 
          <br/>  Using modern AI tools the characters and settings from the book are brought to life.
          <br/>  We hope to bring new readers to this classic, who, after reading this version, will engage with the full text.
          <br/>
           <a href="https://www.amazon.com/Bridge-San-Luis-Rey-Illustrated/dp/B0C9SDHKXH/ref=sr_1_1?crid=22CLA92C5TUQZ&keywords=Bridge+of+San+Luis+Rey+abridged&qid=1689958320&sprefix=bridge+of+san+luis+rey+abridge%2Caps%2C199&sr=8-1">
            Purchase on Amazon
          </a>
          </Typography>
        </Box>
    )
}
