import React, { useCallback, useEffect, useState, useRef } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Icon from '@mui/material/Icon';

import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

import ReactJson from 'react-json-view'
import {MatchSelect} from './matchselect'

const parse = require('html-react-parser');

const EntryList = (props) => {
    const [listView,setListView] = useState(true)
    const [detailView,setDetailView]  = useState(<Box>Detailed View</Box>)

    // Managed selected rows
    const [rowSelectionModel, setRowSelectionModel] = useState([]); const selectedRows = useRef(null)
    useEffect(()=>{if ("selected" in props.asset) setRowSelectionModel(props.asset.selected)},[])
    useEffect(()=>{selectedRows.current = rowSelectionModel
      return () => {
        props.asset.selected = selectedRows.current
        // set edge labels for outgoing edges
        // console.log("EntryList...",props)
        const outEdges = props.edges.filter((e)=>e.source===props.nodeID)
        outEdges.map((e)=>e.label=""+props.asset.selected.length)
        // NEED to PROPOGATE this through nodes that point to this node...
      }
    },[rowSelectionModel])

    const cellClicked = (params) => {
      switch (params.field) {
      default:
        console.log("Nothing for this click...but setting stuff",props.asset.entries.find((e)=>e.id===params.id))
        setDetailView(<Box>Well, this is interesting</Box>)
        setListView(false)
        break
      }
    }

    // set up the columns
    const cols = [
      {field:"entry",headerName:"Entry", width:150}
      ]
    // rows
    const rows = []
    props.asset.entries.map((e)=>{
      rows.push({id:e.id, 
                 entry:e.display,
                 selected: "selected" in props.asset ? props.asset.selected.includes(e.id) : false,
               })
    })

    return (
      <>
      { listView && 
      <>
      <Box sx={{ height: '80vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={cols}
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        disableRowSelectionOnClick
        hideFooterPagination
        onCellClick={cellClicked}
      />
    </Box>
    </>
    }
    { !listView && detailView}
    </>
    )
}



const ViewTab = (props) => {

  const [content,setContent] = useState("Loading...")
  useEffect(()=> {
      if (props.type != "primary" || props.asset.primary.type != "html") return
      var doc = document.getElementById("primaryBox"); 
      doc.innerHTML = content; 
   },[content])

  const knownTypes = (asset) => {
    if (asset.location === "Storage") {props.state.fromStorage(asset.value)
        .then((v)=>{console.log("Back from storage with",v.substring(0,50)); setContent(v)})
    }
    if (asset.location === "Web") {
      console.log("Doing an iframe",asset.value)
      return (<Box sx={{height: '500px'}}><iframe height="100%" width="100%" src={asset.value}></iframe></Box>)
    }
    if (asset.location === "Local") {
      console.log("LOCAL HTML string",asset.location,asset.value.substring(0,50))
      const render = parse(asset.value)
      console.log("And the render is",render)
      return (<Box style={{maxHeight: '100%', overflow: 'auto', overflowY: 'auto'}}>{render} </Box>)
    }
    try {
      const render = parse(asset)
      //console.log("And the render is",render)
      return (<Box style={{maxHeight: '100%', overflow: 'auto', overflowY: 'auto'}}>{render} </Box>)
    }
    catch {return (<Box style={{maxHeight: '100%', overflow: 'auto', overflowY: 'auto'}} id="primaryBox"> Unknown... </Box>)}
  }

  switch(props.type) {
  case "primary": 
    return knownTypes(props.asset.primary); 
    break
  case "list":
    return (<EntryList asset={props.asset} edges={props.edges} nodeID={props.nodeID}/>)
    break
  case "default":
    console.log("ACTIVE WITH default case",props)
    if (Object.keys(props.asset).length === 1) {  // just one entry
      const key = Object.keys(props.asset)[0]
      console.log("SINGLE ASSET - sending to knownTypes",key)
      return knownTypes(props.asset[key])
    }
    return (<ReactJson src={props.asset}/>)
    break
  }
}

export const ActiveOverlay = (props) => {

  const [value, setValue] = useState(props.node.data.action === "match" ? "1": "2");

  const handleChange = (event,value) => {
    console.log("Handle Change with", value)
    setValue(value);
  };

  // Decide on default view, based on asset type
  var asset = props.node.data.asset
  if ("parent" in props.node.data.asset) {
    const pnode = props.nodes.find((n)=>n.id === props.node.data.asset.parent)
    if (pnode) {
      console.log("#### Redirect to parent",pnode,pnode.data.asset)
      asset = pnode.data.asset
    }
    else {
      console.log("Parent node not found")
      asset = {primary:"problem finding parent node"}
    }
  }

  var atype = "default"
  if ("entries" in asset) { atype = "list" }
  if ("primary" in asset) { atype = "primary"}

  return (
      <Box sx={{ mt:-4, p:0, height: '100%', width: '100%', borderTop: 0, typography: 'body1' }}>
      <TabContext sx={{height:'100%'}} value={value}>
        <Box sx={{ m:0, p:0, borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange}>
            <Tab label="Services" value="1" />
            <Tab label="View" value="2" />
            <Tab label="Edit" value="3" />
            <Tab label="Raw" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1"><MatchSelect options={props.matches} selection={props.selection}/></TabPanel>
        <TabPanel value="2"><ViewTab  sx={{height:'100%'}} type={atype} asset={asset} nodeID={props.node.id} edges={props.edges} state={props.state}/></TabPanel>
        <TabPanel value="3">Options, matches</TabPanel>
        <TabPanel value="4"><ReactJson src={props.node.data.asset}/></TabPanel>
      </TabContext>
    </Box>
  )
}