import * as React from 'react'
//import {useCallback, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const handleStyle = { left: 10 };

function NullNode({data}) {
  return (
    <>
    <div style={{display: 'flex', alignItems: 'center',justifyContent: 'center', height: '100%'}}>
    {data.label }
    </div>
    </>
  )
}

function OrcNode({data}) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {data.action = "menu"; setAnchorEl(event.currentTarget);};
  const handleMenuClose = (a) => {
    data.action = a
    setAnchorEl(null);
  };

  const action = (a) => {
  //  data.action = a
  }

  const fs = data.expanded ? 30 : 10

  var flip = false
  if ("flip" in data) flip = data.flip

  var color = "blue"
  switch(data.phase) {
    case "match"  : color = "lightgrey"; break;
    case "service": color = "lightgreen"; break;
    case "pending": color = "grey"; break;
    case "results": color = "orange"; break;
    case "active": color = "white"; break;
    case "configure": color = "lightgreen"; break;
    }

  //console.log("Overlay render, setting action to",data.phase)
  //data.action = data.phase
  return (   
      <>
      {flip && <Handle type="source" style={{background:'blue'}} position={Position.Top} />}
      {!flip && <Handle type="target" position={Position.Top} />}
      <div style={{background: color, border: '1px solid black', borderRadius: 9, fontSize: fs, padding: "5px"}}>
          <>
          {' '}{data.label}{' '}
          <span id="basic-menu" 
                aria-controls={open ? 'basic-menu' : undefined} 
                aria-haspopup="true" 
                aria-expanded={open ? 'true' : undefined} 
                style={{color: "black", cursor:"context-menu"}} 
                onClick={handleMenuOpen}> 
                  {'\u2630'}
          </span>{' '}
          {"hasHiddenChildren" in data && data.hasHiddenChildren && "*"}
          <br/>
          {data.phase === "configure" && <em style={{fontSize: 6}}>Configure service here.</em>}
          {data.phase === "service" && <em style={{fontSize: 6}}>Click to start.</em>}
          {data.phase === "pending" && <em style={{fontSize: 6}}>Working...</em>}
          {data.phase === "results" && <em style={{fontSize: 6}}>Results: click to manage</em>}
          </>
 {/*}         {"expanded" in data &&
           <span>{data.expanded 
           ? <><span style={{color: "green"}} onClick={()=>action("collapse")}>{'\u21F2'}</span>{' '}</>
           : <><span style={{color: "green"}} onClick={()=>action("expand"  )}>{'\u21F1'}</span>{' '}</>
          }</span>} */}
      </div>
      {!flip && <Handle type="source" style={{background:'blue'}} position={Position.Bottom} />}
      {flip && <Handle type="target" position={Position.Bottom} />}

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={()=>handleMenuClose("match")}> New asset options</MenuItem>
        <MenuItem onClick={()=>handleMenuClose("match")}> Add to this asset</MenuItem>
        <MenuItem onClick={()=>handleMenuClose("delete")}> Delete entire asset</MenuItem>
        <MenuItem onClick={()=>handleMenuClose("hideChildren")}> Hide Children</MenuItem>
        <MenuItem onClick={()=>handleMenuClose("hide")}> Hide </MenuItem>
        <MenuItem onClick={()=>handleMenuClose("show")}> Show Children</MenuItem>
        <MenuItem onClick={()=>handleMenuClose("flip")}> Flip</MenuItem>
        <MenuItem onClick={()=>handleMenuClose("download")}> Download</MenuItem>
      </Menu>
      </>
  )
}

/*
        <span style={{color: "green"}} onClick={()=>action("+")}>{'\u2295'}</span>{' '}
                  <span style={{color: "green"}} onClick={()=>action("delete")}>{'\u2296'}</span>{' '}

                            <span style={{color: "green", position:"absolute", bottom:-6, left:"15%", cursor:"copy"}} onClick={()=>action("add")}>{'\u25BC'}</span>{' '}
          <span style={{color: "green", position:"absolute", right:-4, cursor:"copy"}} onClick={()=>action("augment")}>{'\u25B6'}</span>{' '}

*/



export {NullNode, OrcNode}