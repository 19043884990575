import react from 'react'
import {useEffect, useState, Fragment} from 'react'
import uuid from 'react-uuid'

import {TopBar} from "./topbar.js"
import {FlowView} from "./flow"

import { getDoc, doc} from "firebase/firestore";

// LOAD data required for the project

export const ActiveProject = (props) => {

    const [ready, setReady] = useState(false)
    const state = props.state
    const fire  = state.getFire()
    const proj  = state.getProject()

    const onChangeCol = (c,qss) => {
        //console.log("Change in",c)
        if (c==='api') console.log("^^^ API Change:::")
 //       if (c === "flow") state.fullMatch()       // update matchlist
    }

    useEffect(()=>{
        // *** may want to add a watch for provider changes, and refresh the fullMatch when that happens.
        const globalCollections = ['providers','api']
        getDoc(doc(fire.db,"config","perproject"))
        .then((d)=>{
            //console.log("Loading project",d.data().collections)
              Promise.all(d.data().collections.map(async (cl)=>{await state.rsCol(cl,onChangeCol,false)}))
              .then((r)=>{
                  Promise.all(globalCollections.map(async (cl)=>{await state.rsCol(cl,onChangeCol,true)}))
                  .then((q)=>{
                    // state.fullMatch(); 
                    setReady(true)
                  })
                  .catch((e)=>console.log("Problem loading global collections",e))
              })
              .catch((e)=>console.log("Problem loading project from Firebase",e))
        })
    },[])


    if (ready) {return ( 
        <div style={{height:"100%"}}>
          <FlowView  state={props.state}/>
        </div> 
    )}
    else return (<b>Loading Project...</b>)
}

 

