import react from 'react'
import {useEffect, useState} from 'react'

// import {RichTextEditor, EditorValue} from './textEditor'

import {TopBar} from "./topbar.js"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';



// var editorValue = new EditorValue();
// console.log("editorValue", editorValue.isState())


export const LandingPage = (props) => {

    const state = props.state 
    const fire = state.getFire()


    return (
        <Box sx={{borderWidth:5, textAlign:"center", width:"100%"}}>
          <br/><br/>
          <Typography variant="h4"> 
              Welcome to Orchestrate.
          </Typography> 
          <br/><br/>  
          <TextField autoFocus id="whatToDo" label="What would you like to do?" defaultValue="Book to Storyboard" variant="outlined"/>
          <br/><br/><br/>
          <Typography variant="body1"> 
          1. Find a template by searching above.
          </Typography>
          <br/>
          <Typography variant="body1">
          2. Choose an existing Project.
          </Typography>
          <br/>
          <Typography variant="body1">
          3. Browse templates to get started faster.
          </Typography>
          
        </Box>
    )
}

