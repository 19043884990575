import 'reactflow/dist/style.css';
import './flow.css';

import React, { useCallback, useEffect, useState, useRef, useMemo } from 'react';
import ReactFlow, {addEdge, MiniMap,Controls, Background, useNodesState, useEdgesState, MarketType,Position,} from 'reactflow';
import uuid from 'react-uuid';

import { ref, uploadBytes, getBytes, deleteObject } from "firebase/storage";
import { collection, getDocs, setDoc, getDoc, addDoc, doc, onSnapshot, deleteDoc, updateDoc } from "firebase/firestore";

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import {OverlayBox} from "./overlay"

import {OrcNode} from "./customnodes"

// ==== FLOW
 
const proOptions = { hideAttribution: true };
const onInit = (reactFlowInstance) => console.log('flow loaded:', reactFlowInstance);


export const FlowView = (props) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);  const nref = useRef();
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);  const eref = useRef();
  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
  const onNodeDragStart = (event, node) => console.log('drag start', node);
  const onNodeDragStop = (event, node) => console.log('drag stop', node);
  const onNodeClick = (event, nd) => {console.log("GOT A CLICK",nd); doNodeClick(nd)};
  const onEdgeClick = (event, ed) => {console.log("Edge click"); doEdgeClick(ed)};
  const onPaneClick = (event) => console.log('onPaneClick', event);
  const onPaneScroll = (event) => console.log('onPaneScroll', event);
  const onPaneContextMenu = (event) => console.log('onPaneContextMenu', event);

  const [overlay,setOverlay] = useState(false)
  const [selected,setSelected] = useState(null)
  const [serviceMap, setServiceMap] = useState([])
  const forceRender = () => {setNodes(nodes=>[...nodes]);setEdges(edges=>[...edges]);}
 
  const state = props.state
  const fire = state.getFire()
  const proj = state.getProject()
  const colName = "flow"

  // CURRENT COPY OF NODES AND EDGES FOR DISMOUNT
  useEffect(()=>{nref.current = nodes},[nodes])
  useEffect(()=>{eref.current = edges},[edges])

  // ==INITIALIZE==
  useEffect(()=>{   
    loadFlows() 
    props.state.subCol("results",haveResults)             
    setServiceMap(serviceMapInit())
    return (()=>{storeFlows(); state.unsubCol("flow")})
   },[])

  window.addEventListener("beforeunload", (ev) => {ev.preventDefault();storeFlows();});

  const loadFlows = () => {
    setNodes(state.getColList("flow").filter((nre)=>nre.ne==="node"))
    setEdges(state.getColList("flow").filter((nre)=>nre.ne==="edge"))
  }
  // ensure there are no "undefined" in the document
  const cleanDoc = (d) => {
    if (d === null) return ""
    if (Array.isArray(d)) {return d.map((e)=>cleanDoc(e))}
    if (!typeof d != 'object') {if (d != null) {return d} else {return ""}}
    const r = {}; for (const [k,v] of Object.entries(d)) {r.k = cleanDoc(v)}; return r
  }

  const storeFlows = async () => {
    const fulllist = nref.current.concat(eref.current)
    return Promise.all(fulllist.map(async (n)=>{
      await setDoc(doc(fire.db,"projects",proj.id,"flow",n.id),n,{merge:true})
            .then(()=>{})
            .catch((err)=>console.log("SetDoc error with",err,n))
    }))
  }

  // ==RESULTS==
  const haveResults = (c,qss) => {
    if (c != "results") {console.log("Unexpected update from",c); return;}
    qss.docChanges().forEach(change => {
      if (change.type === 'added') { //} || change.type == "modified") {
        const res = change.doc.data()
        const node = nref.current.find(n=>{try {return (n.data.job.jobID === res.jobID)} catch{return(false)}})
        if (!node) {return(false)}  // node may have deleted
        if (node.data.phase === "pending") {  // Need to be in pending to process results
          node.data.phase = "results";    
          node.data.job.results = typeof(res.response) === "string" ? JSON.parse(res.response) : res.response // response may be string JSON, or pure JSON
          const jobresults = {id: res.jobID,provider: res.response.ProviderName,service: res.response.display,}
          if (!jobresults.provider || jobresults.provider === undefined) jobresults.provider = "ProviderName not defined"   // should NOT NEED THIS
          if (!jobresults.service  || jobresults.service  === undefined) jobresults.service  = "Unknown service"   // should NOT NEED THIS

          setDoc(doc(fire.db,"api","api","pastjobs",res.jobID),jobresults).catch((e)=>console.log("Trouble storing past job",jobresults))
          Promise.all([
            deleteDoc(doc(fire.db,"api","api","jobs",res.jobID)),
            deleteDoc(doc(fire.db,"api","api","results",res.jobID)),
            deleteDoc(doc(fire.db,"projects",proj.id,"results",res.jobID)),
          ])
          .then((s)=>{setNodes(nodes => [...nodes]); console.log("Job cleanup done"); console.log("selected is",selected); setSelected(selected) })
          .catch((e)=>console.log("Problem cleaning up after job",e))
        }
      }})
  }

  const doEdgeClick = (ed) => {
    console.log("Handling edge click")
  }

  const setHiddenKids = (nd,visible,me) => {
      nd.hidden = me 
      const cedges = edges.filter((e)=>(e.source === nd.id)) // all outbound edges
      cedges.forEach((e)=>{
        e.hidden = visible
        const mn = nodes.find((n)=>n.id === e.target) // node at end of edge
        if (mn) {if (mn.hidden != visible) setHiddenKids(mn,visible,visible)} // only recurse if not already set
      })
  }

  const setHiddenInboundEdges = (nd,visible) => {
    const edge = edges.filter((e)=>e.target === nd.id)  // edges that point here
    if (edge.length > 0) {nd.data.hasHiddenChildren = visible} else {nd.data.hasHiddenChildren = false}
    edge.map((e)=> {
      e.hidden = visible    // hide the edge
      const parent = nodes.find((n)=>n.id == e.source)
      if (parent) parent.data.hasHiddenChildren = visible
    })
  }

  const setHidden = (nd, visible) => { setHiddenInboundEdges(nd, visible); setHiddenKids (nd, visible,visible)}
  const setHiddenChildren = (nd) => {setHiddenKids(nd,true,false)}  // current node not hidden

  const downloadData = (json,name) => {
      var blob1 = new Blob([JSON.stringify(json)], { type: "text/plain;charset=utf-8" });
      var isIE = false || !!document.documentMode;
      if (isIE) {window.navigator.msSaveBlob(blob1, name+".txt");}
      else {
          var url = window.URL || window.webkitURL;
          var link = url.createObjectURL(blob1);
          var a = document.createElement("a");
          a.download = name+".txt";
          a.href = link; document.body.appendChild(a); a.click(); document.body.removeChild(a);
      }
  }

  const doDownload = async (data) => {
    if (!"asset" in data) {console.log("Nothing to download"); return}
    if ("primary" in data.asset && "location" in data.asset.primary && data.asset.primary.location === "Storage") {
      const toStore = await state.fromStorage(data.asset.primary.value)
      downloadData({"primary":data.asset.primary, "data":toStore},data.label)
      return;
    }
    if ("entities" in data.asset) {
      downloadData ({"entitites":data.asset.entities},data.label)
    }
  }

  // ==CLICK ON A NODE== 
  // Handle all the different cases, based on the Nodes phase
  const doNodeClick = (nd) => {
    const action = nd.data.action
    const node = nodes.find((n)=>n.id === nd.id)  // get node that was clicked

    switch (action) {
      case "menu": return
      case "delete":       onDelete(node.id);return
      case "hideChildren": node.data.action = ""; setHiddenChildren(node); forceRender(); return
      case "hide":         node.data.action = ""; setHidden(node,true); forceRender(); return
      case "show":         node.data.action = ""; setHidden(node,false); forceRender(); return
      case "flip":         node.data.action = ""; 
                           if ("flip" in node.data) {node.data.flip = !node.data.flip} else {node.data.flip = true}; 
                           node.position.x = node.position.x+1; 
                           forceRender(); return
      case "download":     node.data.action = ""; doDownload(node.data); return
      default: 
    }
    
 /*   if (action === "delete")        {onDelete(node.id);return}
    if (action === "hideChildren")  {node.data.action = ""; setHiddenChildren(node); forceRender(); return}
    if (action === "hide")          {node.data.action = ""; setHidden(node,true); forceRender(); return}
    if (action === "show")          {node.data.action = ""; setHidden(node,false); forceRender(); return}
    if (action === "flip")          {node.data.action = ""; if ("flip" in node.data) {node.data.flip = !node.data.flip} else {node.data.flip = true}; node.position.x = node.position.x+1; forceRender(); return}
    if (action === "download")       {node.data.action = ""; doDownload(node.data); return} */

    var matchlist = generateMatches(node)
    const ovList = ["match","configure","service","results","active"]
    if (ovList.includes(node.data.phase)) {
      setSelected({node:node,nodes:nodes,edges:edges,
                   matchlist: matchlist,
                   state:props.state,
                   addANode:addANode,addAnEdge:addAnEdge,onDelete:onDelete,
                 });
      setOverlay(true)
    }
  }

  const generateMatches = (nd) => {
    const am = assetMap()
    const matches = []
    serviceMap.forEach((s)=>{ // services all of whose inputs are met with current assets
      const check = s.inputs.map((i)=>(am.includes(i)))
      if (check.every(e=> e===true)) {matches.push(s)}
    })
    const ms = matches.filter(m => m.inputs.includes(nd.data.path))
    return ms
    }
  
  //*** Should look into the Asset object for path
  const assetMap = () => { return [...new Set(nodes.map((n)=>n.data.path))] }

  // only need to do this once?  Move to project and store in State?
  const serviceMapInit = () => {
    const sm = []
    state.getColList('providers').forEach((pv)=>{
      pv.provides.forEach((service)=>{
        var ins = (Array.isArray(service.inputs)) ? service.inputs  : [service.inputs]
        sm.push({inputs:ins,provider:pv,service:service})
      })
    })
    return sm
  }

  const overlayClosed = (v) => {setSelected(null); setOverlay(false); forceRender();}

  const addANode   = (nn ) => setNodes(nodes => [nn,...nodes])
  const addAnEdge  = (ee ) => setEdges(edges => [ee,...edges])
  const onDelete   = (nid) => {
    // HAVE TO CHECK FOR "HANGING NODES" that are unconnected once an edge is removed....
    // Delete assets and/or pending jobs / results
    const node = nodes.find((n)=>n.id === nid)
  //  console.log("Deleting node",node)
    if ("job" in node.data) {  // means a pending job??  *** Possible that it's out to the provider and comes back...
        deleteDoc(doc(fire.db,"api","api","jobs",node.data.job.jobID))
        deleteDoc(doc(fire.db,"api","api","results",node.data.job.jobID))
        deleteDoc(doc(fire.db,"projects",proj.id,"results",node.data.job.jobID))
    }
    try {if ("asset" in node.data) {
       node.data.asset.forEach((a)=>{
         if (a.data.location === "Storage") deleteObject(ref(fire.storage,a.data.value))
       })
    }} catch {}
    const el = edges.filter(e=>(e.target === nid || e.source === nid))
    el.map(e=>deleteDoc(doc(fire.db,"projects",proj.id,"flow",e.id)))
    deleteDoc(doc(fire.db,"projects",proj.id,"flow",nid))
    setEdges(edges => edges.filter(e=>(e.target != nid && e.source != nid)))
    setNodes(nodes => nodes.filter(e=>e.id != nid))
  }

  const dumpFlow = () => {
    console.log("DUMP flow",nodes)
    console.log("Project results",state.getCol("results"))

  }
  const nodeTypes = useMemo(() => ({ orcNode: OrcNode }), []);

  return (
    <>
        <ReactFlow
          nodes={nodes}                   edges={edges}
          onNodesChange={onNodesChange}   onNodeClick={onNodeClick}
          onEdgeClick={onEdgeClick}       onEdgesChange={onEdgesChange}
          onConnect={onConnect}           onInit={onInit}
          nodeTypes={nodeTypes}           proOptions={proOptions}
          fitView
        >
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow>

        <Button onClick={dumpFlow}>Dump</Button>

        {overlay && <OverlayBox
               onClose={overlayClosed}
               open={overlay}
               value={selected}
        />}     
     </>
  );
};



