
import {useState, useEffect, Fragment} from 'react'

import LoadingOverlay from 'react-loading-overlay';
import uuid from 'react-uuid'

import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import { collection, getDoc, getDocs, setDoc, addDoc, doc, updateDoc, onSnapshot, deleteDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getBytes, deleteObject } from "firebase/storage";


function ab2str(buf) {return new TextDecoder().decode(buf);}

const LoadStory = (props) => {

    const [ready,setReady] = useState(true)

 //   const state = props.state 
 //   const proj = props.state.getProject()
    const fire = props.state.getFire()
    const node = props.node


    const loadTheFile = (filename) => {
        setReady(false);
        const storageName = uuid();
        const sRef = ref(fire.storage, storageName);

        console.log("The filename is",filename)

        uploadBytes(sRef,filename)
          .then((s)=>{

            console.log("Updating the node",node.id)

            node.data.asset.primary = {
                location: "Storage",
                value: storageName,
                type: "html"
            };
            node.data.label = "Story"
            node.data.path  = "Story"
            //node.phase = "active"
            props.onSuccess();
            setReady(true);
          })
          .catch((e)=>console.log("Problem loading file",e))
    }

    // console.log("Load Story UI")

    return ( 
       <LoadingOverlay active={!ready} spinner text="Loading..."> 
            <Box display="flex" justifyContent="center" alignItems="top" minHeight="100vh">
                    <input type="file" id="fileToUpload" style={{display:"none"}} onChange={(e)=> loadTheFile(e.target.files[0])}/>
                    <label htmlFor="fileToUpload"> <Button component="span">  Upload File </Button> </label>

            </Box>
        </LoadingOverlay> 
    )

}

export {LoadStory}

