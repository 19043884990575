import react from 'react';
import {useEffect, useState} from 'react'
import './App.css';


import {state} from "./state/state"

import {TopBar} from "./components/topbar"
import {LandingPage} from "./components/landing"
import {SplashPage} from "./components/splash"
import {ProjectsPage} from "./components/projects"
import {ActiveProject} from "./components/project"
import {BookPage} from "./components/books"

import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';

// OpenAI Key:  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


//const SignInOut = <SignInBlock state={state}/>

const columns: GridColDef[] = [
  {field: 'projectType',headerName: 'Description',width: 400,editable: true,},
  {field: 'author',     headerName: 'Author',     width: 200,editable: true,},
  {field: 'rating',     headerName: 'Rating',     width: 150,editable: true,},
];

const rows = [
  { id: 1, projectType: 'Book to Storyboard', author: 'Jonathon Spark Jr.', rating:"***" },
  { id: 2, projectType: 'Create SF book', author: 'Ayaka' },
  { id: 3, projectType: 'Graphic Novel', author: 'Millicent', rating: "*****" },
  { id: 4, projectType: 'Classic novel to Screenplay', author: 'Jon bon Jovi' },
  { id: 5, projectType: "Script to Storyboard", author: 'Dr. Evil'},
  { id: 6, projectType: "Screenplay to Operations Plan", author: "Miss Independent", rating: "***"}
];

function Templates() {
  return (
    <Box sx={{ height: '85vh', width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}



function App() {

  // State Machine
  const [machine,setMachine] = useState(state.currentState())
  useEffect(() => {
    if (machine != "Out") {document.body.style.backgroundColor = "rgba(245,245,250,1)";}
    else document.body.style.backgroundColor = "rgba(0,0,0,0.5)";
  },[machine])
  state.registerForStateChange((s)=>{if (machine === state.currentState()) return; setMachine(state.currentState())})


  switch(machine) {
    case "Out":       return (<div><SplashPage     state={state}/></div>)
    case "Land":      return (<div><TopBar state={state}/> <LandingPage    state={state}/></div>)
    case "Projects":  return (<div><TopBar state={state}/> <ProjectsPage   state={state}/></div>)
    case "Active":    return (<div style={{height:'90vh'}}><TopBar state={state}/> <ActiveProject  state={state}/></div>)
    case "ShowCase":  return (<div style={{height:'60vh'}}>
                                  <TopBar state={state}/> 
                                  <div style={{height:"90vh", display:"flex",marginLeft:"50px"}}>
                                     <img  src="./images/storyboards.png" alt="Storyboards"/>
                                  </div>
                              </div>)
    case "Team":      return (<div style={{height:'90vh'}}><TopBar state={state}/> <img src="./images/teampics.png" alt="Storyboards" width="100%"/> </div>)
    case "Templates": return (<div style={{height:'90vh'}}><TopBar state={state}/> <Templates/> </div>)
    case "Books":     return (<div><BookPage/></div>)
    default: {console.log("machine in bad state",machine); return (<div>Bad Machine State</div>)}
  }

}

export default App;


