import React, { useCallback, useEffect, useState, useRef } from 'react';
import ReactFlow, {addEdge, MiniMap,Controls, Background, useNodesState, useEdgesState, MarketType,Position,} from 'reactflow';
import uuid from 'react-uuid';

import 'reactflow/dist/style.css';
import './flow.css';

import { ref, uploadBytes, getBytes } from "firebase/storage";
import { collection, getDocs, setDoc, getDoc, addDoc, doc, onSnapshot, deleteDoc, updateDoc } from "firebase/firestore";

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ReactJson from 'react-json-view'

// UI's for node clicks.

import {LoadStory} from "./loadstory"
import {MatchSelect} from './matchselect'
//import {ActiveNode} from './activenode'
import {ActiveOverlay} from "./active"

import {NullNode} from "./customnodes"

import {runInBrowser} from "./jobsInBrowser"
import {UiInBrowser} from "./uiInBrowser"

const UIMap = {
  'LoadStory': <LoadStory/>,
}

function OverlayBox(props) {
  const {onClose, open, value } = props;
  const [phase,setPhase] = useState(null)

  console.log("Overlay Box opening with",props)
  if (value === null) onClose(false)

  const node = value.node
//  const meta = node.meta
  const state = value.state
  const fire = state.getFire()
  const proj = state.getProject()

  useEffect(()=>{
    const node = props.value.node
    if (!("phase" in node.data)) {setPhase("error"); return}
    setPhase(node.data.phase)
  },[])

  const findNodeWithAsset = (a) => {
    /// DONT THINK THIS WORKS YET - NAME IS JUST THE LAST ELEMENT OF THE PATH
    // console.log("Trying to find the node that owns",a)
     const fn = props.value.nodes.filter((n)=>(n.data.path.indexOf(a) != -1) && a !== "")
     return (fn[0])
  }

  const edgefrom = async (a,b) => {
    const newedge = {
      id: uuid(),
      ne: "edge",
      label: "asset" in a.data && "selected" in a.data.asset ? "["+a.data.asset.selected.length+"]" : "",
      source: a.id,
      target: b.id,
      data: {path: a.data.path}
    }
    value.addAnEdge(newedge)
  }

  const orcUI = (provider,service) => {return (provider.name == "Orchestrate" && "ui" in service)}

  // === Called when user adds new node (asset) through Match interface
  const matchSelect = (i) => {
    const match = value.matchlist[i]
    const newnode = {
      id: uuid(),
      ne: "node",
      type: "orcNode",
      data: { label: match.service.display,
              phase: orcUI(match.provider,match.service) ? 'service' : 'configure',
              path: '<<pending>>',
              asset: {},
              service: match.service,
              provider: match.provider,
            },
      position: { x: node.position.x, y: node.position.y + 100 },
    }
    node.data.action = node.data.phase // Match action is done
    props.value.addANode(newnode)

//    console.log("Node added, checking for edges",value.matchlist[i].service.inputs)

    if (Array.isArray(value.matchlist[i].service.inputs)) {value.matchlist[i].service.inputs.map((a)=>edgefrom(findNodeWithAsset(a),newnode))}
    else edgefrom(node,newnode)

    const outlinks = Array.isArray(value.matchlist[i].service.outputs) ? value.matchlist[i].service.outputs : [value.matchlist[i].service.outputs]
    outlinks.forEach((ol)=>{
      //find the part before the last segment  or *.last segment.  If that path exists, add a link
      var ptt
      if (ol.indexOf("*") != -1) ptt = ol.substring(0,ol.indexOf("*")-1)
      else if (ol.lastIndexOf(".") != -1) ptt = ol.substring(0,ol.lastIndexOf("."))
      else ptt = ol 
    //  console.log("CHECKING OUTPUT PATH,",ptt)
      const node = value.nodes.find((n)=>n.data.path === ptt)
      if (node) {
        edgefrom(newnode,node)
        newnode.data.flip = true
      }
    })

// AND, if asset parent already exists, edge from the output of this new node, back to the parent

    onClose(true)
  }

// Service has been selected; send it to be processed.
  const ServicePhase = async (node,onSuccess) => {
      if (node.data.provider.name == "Orchestrate" && "ui" in node.data.service) {
         console.log("Internal with",value.node)
         content = React.cloneElement(UIMap[node.data.service.ui],{node: node, state: state, onSuccess:onSuccess})
       }
      else {
        console.log("MARKET: got job")
        const assetList = Array.isArray(node.data.service.inputs) ? node.data.service.inputs : [node.data.service.inputs]
        // Put together the data
        const reqs = []
        assetList.forEach((asset)=> {
            //*** Need to handle case where there is an *
          const owner = value.nodes.find((n)=>n.data.path === asset)
          // FILTER entries to those in SELECTED
          const localAsset = {...owner.data.asset}
          if ("entries" in localAsset && "selected" in localAsset) {localAsset.entries = localAsset.entries.filter((e)=>localAsset.selected.includes(e.id))}
          reqs.push({asset:asset,data:localAsset})
        })
        // Have the assets packaged up
        console.log("MARKET: assetlist", reqs)
        const job = {projID: proj.id,
                     jobID: uuid(),
                     assets: reqs,
                     params: "params" in node.data ? node.data.params : {},
                     service: node.data.service, 
                     stage: "process"}
        node.data.job = job

        node.data.phase = "pending"
        setPhase("pending")
        // Check if we can run the job in the browser, or need to send to Firebase Function
        
        const inBrowser = await runInBrowser(job,state)
        console.log("runInBrowser has returned",inBrowser)

        console.log("THE CHECK FOR IN BROWSER has returned this",inBrowser)
        if (inBrowser === false) {
          setDoc(doc(fire.db,"api","api","jobs",job.jobID),job)
          .then((j)=>{console.log("Job submitted okay",j); setPhase("pending")})
          .catch((e)=>console.log("^^^^^^^^^^Problem with job",e))
        }
        else {console.log("RUNNING JOB IN BROWSER",job)}


      }
  }

  const acceptResults = () => {
    node.data.phase = "active";

    const results = node.data.job.results
    const outputs = Array.isArray(node.data.job.service.outputs) ? node.data.job.service.outputs : [node.data.job.service.outputs]

  //  console.log("Checking results: outputs", outputs)
   // console.log("With...",results)

    // results should map to outputs.
    // if there is a * in Outputs, it means we should expect a list... i.e. node.asset will be a list

    outputs.forEach((asset)=>{
      // first section (up to * if it exists, or up to last element) is the path for this asset; 
      // last element is the new asset - list if * exists
      const isList = asset.includes(".*")
      const segments = asset.split('.')
      const field = segments[segments.length-1] // last element is our new field
      const basepath = isList ? segments.slice(0,-2).join('.') : segments.slice(0,-1).join('.')
      const baseNode = (basepath === "") ?  undefined : value.nodes.find((n)=>n.data.path === basepath)


 //     console.log("Adding asset - list",isList,'field',field,'basepath',basepath,'basenode?',baseNode);

      if (baseNode  &&  (!("primary" in results))) {   // add to baseNode asset
  //        console.log("CASE: Adding asset to basenode; this node has full path and segment label")
          node.data.path = basepath+"."+field
          node.data.label = field
          node.data.asset = {parent: baseNode.id}

          if (isList) {  
            if ("entries" in baseNode.data.asset) {     // add to existing entries
               results.entries.forEach((r)=>{
                 const e = baseNode.data.asset.entries.find((e)=>e.id === r.id)
                 if (e) {e[field] = r[field]}
                 else {console.log("!!!!!!!!!!! Error - could not find entry")}
             })
            }
            else {                // first time a list at this node; generate id's for entries
              const newentries = []
              results.entries.forEach(r=>{
                const nn = r
                nn.id = uuid()
                newentries.push(nn)
              })
              baseNode.asset.entries = newentries
              baseNode.selected = newentries.map((e)=>e.id)
            }
          }  // new field, vs entries
          else {  baseNode.data.asset.field = results.field }

      } else  {  // No basenode owns this asset yet. either new asset, or if "primary", store asset here vs in parent   
  //        console.log("CREATING NEW ASSET")   // implies either a new "primary" or a new "entry".  In either case, path is segment 1
          node.data.path = (basepath === "") ? segments[0] : basepath
          node.data.label = isList ? segments[segments.length-3] : segments[segments.length-1]
    //      console.log("PATH IS -----> ",node.data.path)
   //       node.data.asset = basepath
          if (isList) { 
            const al = []
            results[field].forEach((r)=>{
              const entry = {};
              entry.id = uuid(); 
              entry[field] = r;
              if ("display" in r) {entry.display = r.display} else {entry.display = "Display not set"}    // Display is taken for the creator of the asset. 
      //          console.log("Pushing",al)
              al.push(entry)
            })
            node.data.asset.entries = al
            node.data.asset.selected = al.map((e)=>e.id)  // everything selected by default
         }
          else  {
            node.data.asset[field] = results[field]
            if (!results[field] && "primary" in results) node.data.asset[field] = results.primary
          }
      }
    })
    setPhase("active")
  }

  const onSuccess   = () => {node.data.phase = "active"; setPhase("active"); onClose(true)}
  const onAccept    = () => {acceptResults() ; onClose(true)}
  const handleClose = () => {node.data.action = node.data.phase; onClose(false);};
  const DumpState   = () => {console.log("In Overlay with",phase,node); console.log("And nodes is",value.nodes.find(n=>n.id === node.id))}
  const onDelete    = () => {value.onDelete(value.node.id); onClose(false);}

  if (phase === null) {return (<Box>Loading.... </Box>)}
  var content = <Box>Working...</Box>// <Box>Problem:<ReactJson src={value}/></Box>
  switch(phase) {
    case "match": 
          console.log("PHASE - MATCH")
          content = <MatchSelect options={value.matchlist} selection={matchSelect}/>
          break;
    case "configure":
          var params = {}
          const setParams = (p) => {params = p}
          console.log("PHASE - CONFIGURE")
          content = <>{UiInBrowser(node.data,setParams)}
                    <Button onClick={()=>{console.log("Got params",params); node.data.params=params; node.data.phase="service"; setPhase("service")}}>Continue</Button>
                    </>
          break
    case "service": 
          console.log("PHASE - SERVICE")
          ServicePhase(node,onSuccess)
 //         content = <Box> Job is running; you may close this window and work on other things while it processes. </Box>
          break;
    case "pending":
          console.log("PHASE - PENDING")
          content = <><Box>Waiting for service provider to complete... You may close this window and work on other things in the meantime.</Box></>
      //    onClose(true)
          break;
    case "results":
          console.log("PHASE - RESULTS")
      // get results back from market
          try {
            content = <><Box>Have results</Box>
                        <ReactJson src={node.data.job.results}/>
                        <Button onClick={onAccept}>Accept</Button></>
          }
          catch {
            content = <><Box>Have results</Box>
                        <Button onClick={onAccept}>Accept</Button></>
          }
          break;
    case "active":
          console.log("PHASE - ACTIVE")
          content = <ActiveOverlay sx={{height:'100%'}} node={node} nodes={value.nodes} edges={value.edges} state={state} matches={value.matchlist} selection={matchSelect}/>
          break;
    default:
          console.log("Something wrong",phase,props)
  }

  

  return (
      <Dialog sx={{"& .MuiDialog-container": {"& .MuiPaper-root": {width: "98%", minWidth: "98%", height:"95%", minHeight: "95%" },},}} 
              onClose={handleClose} 
              open={open}
      >
        <DialogTitle>{value.display}</DialogTitle>
        {content}
        <Button sx={{top: 0, right:0, position: "absolute"}} onClick={handleClose}>{'\u24CD'}</Button>
      </Dialog>
 
  ) 
}

export {OverlayBox}