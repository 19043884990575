import * as React from 'react';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));



export const SplashPage = (props) => {
    return (
        <div>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
  {/*}              <MenuIcon /> */}
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Orchestrate
              </Typography> 
              {props.state.getComponent("SignInOut")} 
            </Toolbar>
          </AppBar>
        </Box >
        <Stack >
            <Item style={{backgroundColor:'#000020', opacity:0.7}}><h1 style={{color:"white"}}>Humans and AIs Working Together</h1></Item>
            <Item style={{backgroundColor:'#000000', opacity:0.5}}><h2 style={{color:"white"}}>Coming Soon</h2></Item>
 {/*}           <Item>{props.state.getComponent("SignInOut")}</Item> */}
        </Stack>
        </div>
    )
}
