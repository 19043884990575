import React, { useCallback, useEffect, useState, useRef } from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import ReactJson from 'react-json-view'

export const MatchSelect = (props) => {

   return (
         <TableContainer sx={{width:"100%", margin:"auto", display:"flex", alignItems:"center", }} component={Paper}>
            <Table sx={{justifyContent:"center"}}>
              <TableHead>
                <TableRow sx={{}}>
                   <TableCell align="center">Provider</TableCell>
                   <TableCell align="center">Service</TableCell>
                   <TableCell align="center">Select</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.options.map((match,i)=>(
                  <TableRow key={i}>
                    <TableCell align="center">{match.provider.display}</TableCell>
                    <TableCell align="center">{match.service.display}</TableCell>
                    <TableCell align="center"><Button onClick={()=>props.selection(i)}>Go</Button></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )
}