
import {fire, SignInBlock} from './firebase'
import { collection, getDocs, getDoc, addDoc, setDoc, doc, onSnapshot, deleteDoc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getBytes, deleteObject } from "firebase/storage";

function ab2str(buf) {return new TextDecoder().decode(buf);}

class GlobalState {
	constructor (f) {
		const href = window.location.href.split("/").at(-1)
		this.machine = {currentState: "Out",notify: []}
		if (href==="books") this.machine.currentState = "Books"
			
		this.user = null
		this.components = {}				// components that should render across multiple pages
		this.fire = f
		this.project = {current: null, notify: [],}
		this.collections = {}
		this.cache = {}
	}

	// Collections within a project
	async regCol(c,global)		{var rf = collection(this.fire.db,"projects",this.project.current.id,c)
							     if (global) {rf = collection(this.fire.db,c)}
								 this.collections[c] = {ref: rf,value: {},unsub: null,notify: [],}
								 this.collections[c].unsub = // should probably do delta...
								      onSnapshot(rf,(qss)=>{this.fetchCol(c); this.collections[c].notify.map((f)=>f(c,qss))})
								 return await this.fetchCol(c)
								}
    async fetchCol(c)			{const newvalue = {}
     							 await getDocs(this.collections[c].ref)
     							 .then((ds)=> {
     							   	  ds.forEach((d)=> {newvalue[d.id]=d.data()});
     							   	  this.collections[c].value = newvalue
     							   	  return true
     							 }).catch((e)=>{console.log("fetchCol",c,e); return false})
     							}
    async updateCDU(c,d,u)		{return await updateDoc(doc(this.fire.db,"projects",this.project.current.id,c,d),u)}
    async deleteCD(c,d)			{return await deleteDoc(doc(this.fire.db,"projects",this.project.current.id,c,d))}
    async addCD(c,d)			{return await addDoc(this.collections[c].ref,d)}
    async setCD(c,did,d)		{return await setDoc(doc(this.fire.db,"projects",this.project.current.id,c,did),d)}
    getCol(c)					{try{return this.collections[c].value} catch {return null}}
    getColList(c)				{const r = []; Object.entries(this.collections[c].value).forEach(([k,v])=>{r.push(v)}); return r}
    getCD(c,d)					{try{return this.collections[c].value[d]} catch {return null}}
    unsubCol(c)					{try{this.collections[c].unsub()} catch {}}
    subCol(c,cb)				{try{this.collections[c].notify.push(cb)} catch {console.log("subCol",c,)}}
    async rsCol(c,cb,global)	{const ret = await this.regCol(c,global); this.subCol(c,cb); return ret}

	// State Machine functions
	registerForStateChange(f) 	{this.machine.notify.push(f)}
	changeState(s) 				{this.machine.currentState = s;this.machine.notify.map((f)=>f(s))}
	currentState() 				{return this.machine.currentState}
	signIn() 					{this.changeState("Land")}
	signOut() 					{this.changeState("Out")}
	isSignedIn() 				{return (this.machine.currentState != "Out")}

	// User functions
	setUser(u)					{this.user = u;}
	getUser()					{return this.user}

	// ActiveProject	
	getProject()				{return this.project.current}
	setProject(p,t)				{this.project.current = p; this.project.type = t; this.project.notify.map((f)=>f(this.project.current))}
	clrProject()				{this.project.current = null; this.project.notify.map((f)=>f(null))}
	registerForProjChange(f)	{this.project.notify.push(f)}

	// Components
	addComponent(n,c)			{this.components[n] = c;}
	getComponent(n)				{return this.components[n]}
	getComponents()				{return this.components}

	// Firebase
	setFire(f)					{this.fire = f}
	getFire()					{return this.fire}

	// Cache 
	async fromStorage(id)		{if (id in this.cache) {return this.cache.id}
							     else {
							     	const sRef = ref(fire.storage, id);
							     	const bstr = await getBytes(sRef)
							     	this.cache.id = ab2str(bstr)
							     	return this.cache.id
							     }
								}
	async toStorage(id,d)		{console.log("============== NOT IMPLEMENTED")}
}

// State: Load defaults and attach Global Components and Firebase

//const js = null
const state = new GlobalState(fire);
state.addComponent("SignInOut",<SignInBlock state={state}/>)

export {state}