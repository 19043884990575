import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from "@mui/material";

const theme = createTheme({      
  typography: {
    button: {
      textTransform: 'none',
      size: 'small'
    },
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
 // <React.StrictMode>
     <ThemeProvider theme={theme}>
         <App />
     </ThemeProvider>
 // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
