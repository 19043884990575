import React from 'react'
import {useEffect, useState} from 'react'
import uuid from 'react-uuid'

import {TopBar} from "./topbar"
import {ActiveProject} from "./project"

import Button from '@mui/material/Button';
import Icon from '@material-ui/core/Icon';


import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';


import { getStorage, ref, uploadBytes, deleteObject } from "firebase/storage";
import { collection, getDoc, getDocs, addDoc, setDoc, doc, onSnapshot, deleteDoc } from "firebase/firestore";

export const ProjectsPage = (props) => {

    const [projects,setProjects] = useState([])

    const state = props.state 
    const fire  = state.getFire()
    const proj = state.getProject()
 
    const readProjects = async () => {
        return await getDocs(collection(fire.db,"projects"))
                     .then((r)=>{setProjects(r.docs)})
    }

    useEffect(()=>{  // on first load, check if there is a new project queued in the state; if so, create it.
        readProjects();
        const unsub = onSnapshot(collection(fire.db,"projects"), (d) => readProjects())
        return (() => unsub())
    },[])

    const addProject = () => {
        var n = document.getElementById("newProject").value; 
        document.getElementById("newProject").value = ""
        addProjectByName(n)
    }

    const addProjectByName = async (n) => {
        const initNode = {
            id: uuid(),
            type: 'orcNode',
            ne: "node",
            data: {
                label:n,
                path: "",
                phase: "match",
            },
            position: {x:10,y:100},
        }

        getDoc(doc(fire.db,"config","perproject"))
        .then((d)=>{
            const prj = {
                Name: n,
                collections: d.data().collections,
                createdAt: new Date().toISOString(),
                createdBy: state.getUser().uid
            }
            addDoc (collection(fire.db,"projects"),prj)
            .then((r)=>{
                setDoc(doc(fire.db,"projects",r.id,"flow",initNode.id),initNode)
                .then((r)=>readProjects())
            })
        })
    }

    const selProject = (e) => {state.setProject(e);state.changeState("Active")}

    const delProject = async (e) => {

        getDoc(doc(fire.db,"projects",e.id))
        .then((project)=>{
            getDocs(collection(fire.db,"projects",e.id,"flow"))
            .then((flows)=>{
                Object.entries(flows.docs).forEach(([k,flow])=>{
                    //console.log("Trying with",flow.data())
                    if (flow.data().ne === "node" && "asset" in flow.data().data) {
                        if (flow.data().data.asset.location === "Storage") {
                            try{deleteObject(ref(fire.storage,flow.data().data.asset.value))} catch{}
                        }
                    }
                })
            })
            .then(()=>{
                Promise.all(project.data().collections.map(async (cl)=>{
                await getDocs(collection(fire.db,"projects",e.id,cl))
                     .then(async (ds)=>{await ds.forEach( async (d)=>{
                        await deleteDoc(doc(fire.db,"projects",e.id,cl,d.id))
                     })})
                .then((r)=>{
                     deleteDoc(doc(fire.db,"projects",e.id))
                     .then((t)=>readProjects())
                })}))
            })
        })
    }


    return (      
        <TableContainer>
            <Table sx={{width:"auto", height:"auto"}}>
                <TableBody>
                  {projects.map((e)=>(
                    <TableRow key={e.id}>
                        <TableCell>
                           <Button onClick={()=>selProject(e)}> {e.data().Name} </Button>
                        </TableCell>
                        <TableCell>
                          <Button onClick={()=>delProject(e)}><Icon>delete-circle</Icon></Button>
                        </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                      <TableCell><input id="newProject" type="text"/></TableCell>
                      <TableCell><Button onClick={addProject}><Icon>add_circle</Icon></Button></TableCell>
                  </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

