
import React, { useCallback, useEffect, useState, useRef } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Slider from '@mui/material/Slider';

import Typography from '@mui/material/Typography';


const OpenAIChoices = (setParams) => {
//	const [temp,setTemp] = useState(50)
	const tempChange = (ev, nv) => {console.log("Temp is",nv); setParams({temp:nv/100})}// {setTemp(nv)}
	console.log( "OPEN AI UI")
	return (
		<>
		<Box sx={{borderWidth:5, textAlign:"center", width:"100%"}}>
		  <Typography variant="h4">OpenAI </Typography>
		<br/>
		<Typography variant="body1"> Temperature (creativity) </Typography>
		<br/>
		<br/>
		<Slider sx={{width:"50%", left:"auto", right:"auto", alignItems:"center"}} 
		        valueLabelDisplay="on" 
		        aria-label="Temperature (creativity)"  
		        min={0} 
		        max={100} 
		        defaultValue={50} 
		        onChange={tempChange} />
		</Box>
		</>
	)
}

const NoUI = () => {
	return (<Box sx={{borderWidth:5, textAlign:"center", width:"100%"}}>No configuration required for this job.</Box>)
}


export function UiInBrowser(data,setParams) {
  	switch (data.service.inputs) {
  	case "Story":
		  		switch (data.service.outputs) {
//		  		case "Character.*.Designation": Story2Character(job,state); return true;
//		  		case "Scene.*.Index": 					Story2Index(job,state); 		return true;
//		  		case "GraphicNovel":					  Story2GN(job,state);				return true;
		  		case "Story.Summary":						return OpenAIChoices(setParams);
		  		default: return NoUI()
		  		}
  		break;
  	case "Scene":
 		  		switch (data.service.outputs) {
//		  		case "Scene.*.Summary": 				Scene2Summary(job,state); 	return true;
		  		default: return NoUI()
		  		}
 		break;
  	case "Character":
 		  		switch (data.service.outputs) {
//		  		case "Character.Animation":     Mixamo(job,state); 					return true;
//		  		case "Character.Image":					StableDiffusion(job,state); return true; 
//		  		case "CharacterDashboard":			RealAllusion(job,state);		return true;
//		  		case "Script":									FakeScript(job,state);		  return true;
//		  		case "Screenplay":							FakeScreenplay(job,state);  return true;
		  		default: return NoUI();
		  		}
 		break;
 		case "GraphicNovel":
 		  		switch (data.service.outputs) {
//		  		case "PhysicalBook":     				Printer(job,state); 				return true;
//		  		case "BN_Dashboard":					  Publisher(job,state); 			return true;
		  		default: return NoUI();
		  		}
 		break;
  	default: 
  		return NoUI(); 
  	}
}