import react from 'react'
import {useState, useEffect} from 'react'

import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'firebaseui/dist/firebaseui.css'
import { getAuth, onAuthStateChanged } from "firebase/auth";

import { getFirestore } from "firebase/firestore";
import { collection, getDocs, getDoc, setDoc, doc } from "firebase/firestore";
import { enableIndexedDbPersistence } from "firebase/firestore"; 

import { getStorage, ref } from "firebase/storage";

import { getFunctions } from 'firebase/functions'

import Button from '@mui/material/Button';

var firebaseui = require('firebaseui');

const firebaseConfig = {
  apiKey: "AIzaSyBzDOvW1_La5Wvr7-28YV3v2LxNoV5gATA",
  authDomain: "orchestrate-7dfb7.firebaseapp.com",
  projectId: "orchestrate-7dfb7",
  storageBucket: "orchestrate-7dfb7.appspot.com",
  messagingSenderId: "279927665643",
  appId: "1:279927665643:web:d3629e42bc66d1702a1fae",
  measurementId: "G-DZ8V26ZX4W"
};

const app = firebase.initializeApp(firebaseConfig)
const ui  = new firebaseui.auth.AuthUI(firebase.auth())
const auth = getAuth()
const db = getFirestore(app)
const storage = getStorage(app, "gs://orchestrate-7dfb7.appspot.com");
const functions = getFunctions(app)

enableIndexedDbPersistence(db)
.catch((err)=>console.log("No Firebase Persistence",err))

export const fire = 
 {base:        firebase,
  config:      firebaseConfig,
  app:         app,
  db:          db,
  storage:     storage,
  functions:   functions,
  //  analytics:   getAnalytics(app),
  //  ui:          ui,
 }

 //===== AUTH


export const SignInBlock = (props) => {
  const [signedIn,setSignedIn] = useState(false)

  onAuthStateChanged(auth, (user) => {
     //console.log("Current USER IS",user)

    if (user) {
      if (signedIn) return;
      getDoc(doc(fire.db,"users",user.email))
      .then((u)=>{
        //console.log("User back from database is",u,u.id,u.data(),u.exists)
        if (u.data() != undefined && u.data().allowedToLogin) {
          props.state.setUser(user);
          props.state.signIn();
          setSignedIn(true);
        }
        else {
          if (u.data() != undefined) {
            console.log("User exists, but not allowed to login");
          }
          else { // user does not exist; create them
           // console.log("Creating user; not allowed to login yet")
            setDoc(doc(fire.db,"users",user.email),{displayName:user.displayName,email:user.email,allowedToLogin:false});
          }
          firebase.auth().signOut()
          props.state.signOut()
          setSignedIn(false)
        }
      }).catch((e)=>console.log("onAuthStateChanged issue",e))
    }
    else { // no user
      console.log("No user",user)
      if (!signedIn) return;  // no change
      props.state.signOut();
      setSignedIn(false);
    }
  });

  const uiConfig = {
    callbacks: {'signInSuccessWithAuthResult': function(){return false}},  // no need for signInSuccessUrl
    signInFlow: 'popup',
    signInOptions: [
         {provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
         requireDisplayName:true},
         firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        ],
    signInSuccessUrl: 'app',
  };

  const doSignIn = () => {
    document.getElementById('signInButton').style.display = 'none';
    return ui.start("#fb-auth",uiConfig)
  }

  const doSignOut = () => {
    //setSignedIn(false);
    return firebase.auth().signOut()
  }

  if (signedIn) {  // signed in
    return (<div><Button color="inherit" onClick={doSignOut}>SignOut</Button></div>)
  }
  else {
    return (
      <div>
        <Button color="inherit" id="signInButton" onClick={doSignIn}>Sign In</Button>
        <div id="fb-auth"></div>
      </div>
   )}
}









